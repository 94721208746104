export const textsObjectApps = {
  NoOneAppConected: {
    300: {
      title: 'Your data belongs to you!',
      mainButtonText: 'I understand',
      modalDescription:
        'By connecting your apps you will unlock our actionable insights tool and get high quality credit products based on your credit score.',
    },
    301: {
      title: 'Your data belongs to you!',
      mainButtonText: 'Connect now',
      modalDescription:
        'By connecting your apps you will unlock our actionable insights tool and get high quality credit products based on your credit score.',
    },
    200: {
      title: 'Your data belongs to you!',
      mainButtonText: 'I understand',
      modalDescription:
        'By connecting your apps you will unlock our actionable insights tool and get high quality credit products based on your credit score.',
    },
    104: {
      title: 'Why is it essential to connect one of the business apps you use?',
      mainButtonText: 'I understand',
      modalDescription:
        '<p>- You will get the best financing offers tailored to your needs</p><p>- You will boost your business credit score by connecting apps</p><p>- You will get actionable insights with Business 360 dashboard.</p>',
    },
    500: {
      title: 'Your data belongs to you!',
      mainButtonText: 'I understand',
      modalDescription: '<p>- By connecting your apps you will unlock all available features of CreditPush, such as actionable insights, cash flow forecast, scenario builder and high quality credit products based on your credit score</p>',
    },
  },
  AppsConected: {
    300: {
      title: 'Connect more apps and get more benefits!',
      mainButtonText: 'Continue',
      modalDescription:
        `<ul>
          <li>
            <p>Low-interest loans & credit lines with flexible terms</p>
          </li>
          <li>
            <p>Free tools to boost your business credit score</p>
          </li>
          <li>
            <p>Free business health audit</p><p>If you have no more apps to connect, click Continue.</p>          </li>
        </ul>`,
      closeModalButtonText: 'Connect more apps',
    },
    301: {
      title: 'Connect more apps and get more benefits!',
      mainButtonText: 'Continue',
      modalDescription:
        '<p>- Low-interest loans & credit lines with flexible terms</p><p>- Free tools to boost your business credit score</p><p>- Free business health audit</p><p>If you have no more apps to connect, click Continue.</p>',
      closeModalButtonText: 'Connect more apps',
    },
    200: {
      title: 'Connect more apps and get more benefits!',
      mainButtonText: 'Continue',
      modalDescription:
        '<p>- Low-interest loans & credit lines with flexible terms</p><p>- Free tools to boost your business credit score</p><p>- Free business health audit</p><p>If you have no more apps to connect, click Continue.</p>',
      closeModalButtonText: 'Connect more apps',
    },
    104: {
      title: 'Connect more apps and get more benefits!',
      mainButtonText: 'Continue',
      modalDescription:
        '<p>- Low-interest loans & credit lines with flexible terms</p><p>- Free tools to boost your business credit score</p><p>- Free business health audit</p><p>If you have no more apps to connect, click Continue.</p>',
      closeModalButtonText: 'Connect more apps',
    },
  },
};
