<template functional>
  <div
    :class="['list-item-apps', { connected: props.isAppShared, disabled: props.isHoverDisabled }]"
    @click="listeners.click"
  >
    <img
      :src="props.iconPath"
      class="list-item-apps__icon"
      alt="app-icon"
    >
    <div class="list-item-apps__text caption2">
      {{ props.itemName }}
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    itemName: {
      type: String,
      default: ''
    },
    isHoverDisabled: {
      type: Boolean,
      default: false
    },
    isAppShared: {
      type: Boolean,
      default: false
    },
    iconPath: {
      type: String,
      default: false
    }
  }
})
</script>

<style lang="scss" scoped>
.list-item-apps {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 86px;
  padding: 10px 8px 12px 8px;
  background: $white;
  border: 1px solid $gray-light;
  border-radius: 12px;
  cursor: pointer;
  transition: 0.2s ease;

  &:hover {
    border: 1px solid $blue;
  }

  &__icon {
    width: 88px;
    height: 48px;
    margin-bottom: 4px;
    object-fit: contain;
    object-position: center;
    pointer-events: none;
    user-select: none;
  }

  &__text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
}

.connected {
  border: 1px solid $green;
}

.disabled {
  cursor: default;

  &:hover {
    border: 1px solid $gray-light;
  }
}

@media screen and (max-width: 586px) {
  .list-item-apps {
    &:hover {
      border: 1px solid $gray-light;
    }
  }
}
</style>
