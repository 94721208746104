
import BaseButton from '@/components/baseComponents/BaseButton.vue';
import BaseCirclePreloader from '@/components/baseComponents/BaseCirclePreloader.vue';
import BaseInput from '@/components/baseComponents/BaseInput.vue';
import TitleDescriptionUniversal from '@/components/portal/TitleDescriptionUniversal.vue';
import { textsObjectApps } from '@/texts/textModalAppsPage';
import { getNextPage } from '@/utils/redirectUtils';
import { Component, Vue } from 'vue-property-decorator';
import { mapGetters, mapState } from 'vuex';
import ListItemApps from '@/components/lists/ListItemApps.vue';
import { IService } from '../../models';

const BaseModalForPages = () => import('@/components/baseComponents/BaseModalForPages.vue');

@Component({
  components: {
    TitleDescriptionUniversal,
    ListItemApps,
    BaseCirclePreloader,
    BaseButton,
    BaseModalForPages,
    BaseInput,
  },
  computed: {
    ...mapGetters('steps', ['isPreviousPageExist']),
    ...mapState('steps', ['pageSettings']),
    ...mapState('service', ['appsArray']),
  },
})
export default class AppsPage extends Vue {
  public pageSettings!: any;
  public appsArray!: IService[];
  public isPreviousPageExist!: boolean;

  timerIdServer = 0;
  filteredCategory = '';
  searchField = '';
  isShowModal = false;
  textsObjectApps = textsObjectApps;
  iconCache: Record<string, string> = {};

  get isAppsLoaded(): boolean {
    return this.appsArray.length > 0;
  }
  get connectedAppsList(): IService[] {
    return this.appsArray.filter((app) => app.isShared);
  }
  get notConnectedAppsList(): IService[] {
    const categoryDependingFromPageVersion = this.currentPageVersion === '500' ? 'Accountancy' : this.filteredCategory;
    return this.appsArray.filter((app) => {
      const searchedApps = app.name.toLowerCase().includes(this.searchField.toLowerCase());
      return categoryDependingFromPageVersion.length > 0
        ? !app.isShared && this.categoryByLang(app.category, 'en-US') === categoryDependingFromPageVersion && searchedApps
        : !app.isShared && searchedApps;
    });
  }
  get categoriesList(): string[] {
    return [...new Set(['All apps', ...this.appsArray.map((app) => this.categoryByLang(app.category, 'en-US'))])];
  }
  get currentPageVersion(): string {
    return this.pageSettings.AppsPage;
  }
  get isDisableToConnectMoreThanOneApp(): boolean {
    const { flowNumberVersion } = this.$store.state.tracking;
    const targetFlowNumbers = ['504', '505'];
    return targetFlowNumbers.includes(flowNumberVersion) && this.connectedAppsList.length === 1;
  }

  categoryByLang(category: any, lang: string): string {
    const name = JSON.parse(category)[lang];
    return name;
  }

  getIconPath(itemName: string): string {
    const iconFormats: Record<string, string> = {
      FollowUpBoss: 'png',
      DeskeraBooks: 'png',
    };
    const formattedItemName = itemName.replaceAll(' ', '');
    const fileFormat = iconFormats[formattedItemName] || 'svg';
    if (this.iconCache[itemName]) {
      return this.iconCache[itemName];
    }
    let pathToIcon;
    try {
      // eslint-disable-next-line import/no-dynamic-require, global-require
      pathToIcon = require(`@/assets/apps-icons/${formattedItemName}.${fileFormat}`);
    } catch {
      // eslint-disable-next-line import/no-dynamic-require, global-require
      pathToIcon = require('@/assets/apps-icons/Banks.svg');
    }
    this.iconCache[itemName] = pathToIcon;

    return pathToIcon;
  }
  getTextsForModal(): any {
    const noApps = textsObjectApps.NoOneAppConected[this.currentPageVersion] || textsObjectApps.NoOneAppConected['300'];
    const oneApp = textsObjectApps.AppsConected[this.currentPageVersion] || textsObjectApps.AppsConected['300'];
    return this.connectedAppsList.length === 0 ? noApps : oneApp;
  }
  openIntegration(url: string): void {
    if (!url || this.isDisableToConnectMoreThanOneApp) return;

    const popupWidth = 700;
    const popupHeight = 800;
    const left = window.screen.width / 2 - popupWidth / 2;
    const top = window.screen.height / 2 - popupHeight / 2;
    window.open(
      url,
      '_blank',
      `left=${left},top=${top},height=${popupHeight},width=${popupWidth},status=yes, toolbar=no, menubar=no, location=no, addressbar=no`,
    );
  }
  filterAppsDependingCategories(categoryName: string): void {
    if (categoryName === 'All apps') {
      this.filteredCategory = '';
    } else {
      this.filteredCategory = categoryName;
    }
  }
  selectedCategory(item: string): boolean {
    return item === this.filteredCategory || item === 'All apps' && this.filteredCategory === '';
  }
  async loadAppsList(): Promise<void> {
    try {
      this.timeOutServerError();
      await this.$store.dispatch('service/loadAppsAction');
      clearTimeout(this.timerIdServer);
    } catch (error: any) {
      this.popupError(error.response.data.message);
    }
  }
  nextButtonAction(): void {
    if (this.currentPageVersion === '500' && this.connectedAppsList.length > 0) {
      this.mainButtonModalClicked();
    } else {
      this.openModal();
    }
  }
  async mainButtonModalClicked(): Promise<void> {
    this.closeModal();
    if (this.connectedAppsList.length > 0) {
      this.$router.push({ name: getNextPage() });
    }
  }
  async appConnectionListener(event: any): Promise<void> {
    if (event.data.message === 'VerifyComplete') {
      await this.loadAppsList();
      this.autoRedirect();
    }
  }
  async autoRedirect(): Promise<void> {
    const { flowNumberVersion } = this.$store.state.tracking;
    const validFlowNumbers = ['504', '505', '507'];

    if (validFlowNumbers.includes(flowNumberVersion)) {
      this.$router.push({ name: getNextPage() });
    }
  }
  openModal(): void {
    this.isShowModal = true;
  }
  closeModal(): void {
    this.isShowModal = false;
  }
  appConnectionErrors(): void {
    this.$notify({
      group: 'main-notification',
      type: 'error',
      text: 'Something went wrong! Could you try connection the app again?',
    });
  }
  popupError(errorText: string): void {
    this.$notify({
      group: 'main-notification',
      type: 'error',
      text: errorText,
    });
  }
  timeOutServerError(): void {
    this.timerIdServer = setTimeout(() => {
      this.popupError('the server is not responding');
    }, 10000);
  }
  created(): void {
    this.loadAppsList();
    window.addEventListener('message', this.appConnectionListener);
  }
  mounted(): void {
    if (this.currentPageVersion === '500') {
      this.filteredCategory = 'Accountancy';
    }
  }
  beforeDestroy(): void {
    window.removeEventListener('message', this.appConnectionListener);
    this.$store.commit('service/resetServices');
  }
}
